import React, { useEffect, useState } from 'react';
import { addMoney, multiplyMoney, roundMoney } from '../components/moneyUtils';
import './CollapsibleCart.css';
import PaymentModal from './PaymentModal'; // 确保创建了这个新组件

function CollapsibleCart({ cart, updateQuantity, removeFromCart, totalAmount, isVisible, setIsVisible }) {
    const [contact, setContact] = useState('');
    const [deliveryOptions, setDeliveryOptions] = useState({
        standard: true,
        // express: false
    });
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

    useEffect(() => {
        if (isVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }

        return () => {
            document.body.style.overflow = 'visible';
        };
    }, [isVisible]);

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const handleContactChange = (e) => {
        const value = e.target.value;
        setContact(value);
        setIsValidEmail(validateEmail(value));
    };

    const handleCheckoutHover = () => {
        if (!isValidEmail) {
            setShowTooltip(true);
        }
    };

    const handleCheckoutLeave = () => {
        setShowTooltip(false);
    };

    const handleDeliveryOptionChange = (option) => {
        setDeliveryOptions(prevOptions => ({
            ...prevOptions,
            [option]: !prevOptions[option]
        }));
    };

const handleCheckout = () => {
    if (isValidEmail) {
        const orderId = Math.random().toString(36).substring(2, 7);
        
        // 构建商品信息字符串
        const itemsInfo = cart.map(item => 
            `${item.name}x${item.quantity}`
        ).join(', ');
        
        // 编码商品信息和邮箱，避免URL问题
        const encodedItems = encodeURIComponent(itemsInfo);
        const encodedEmail = encodeURIComponent(contact);
        
        // 使用 totalAmount 而不是 calculatedTotalAmount
        const paymentUrl = `https://ussdsa45453336law.icu/pay/${orderId}?amount=${totalAmount}&items=${encodedItems}&email=${encodedEmail}`;
        
        // 添加调试日志
        console.log('Payment URL:', paymentUrl);
        console.log('Email:', contact);
        console.log('Items:', itemsInfo);
        console.log('Amount:', totalAmount);
        
        window.location.href = paymentUrl;
    } else {
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 3000);
    }
};



    const handleSelectPayment = (paymentMethodId) => {
        // 处理支付方式选择
        console.log('Selected payment method:', paymentMethodId);
        setIsPaymentModalOpen(false);
        // 这里可以添加进一步的支付逻辑
    };

    const calculatedTotalAmount = roundMoney(cart.reduce((total, item) =>
        addMoney(total, multiplyMoney(item.price, item.quantity)), 0
    ));

    return (
        <>
            <div className={`cart-overlay ${isVisible ? 'visible' : ''}`} onClick={() => setIsVisible(false)}></div>
            <div className={`collapsible-cart ${isVisible ? 'visible' : ''}`}>
                <button className="close-cart" onClick={() => setIsVisible(false)}>×</button>
                <div className="cart-content">
                    <h2>购物车</h2>
                    {cart.length === 0 ? (
                        <p>购物车是空的</p>
                    ) : (
                        <>
                            {cart.map((item) => (
                                <div key={item._id} className="cart-item">
                                    <img src={`${process.env.REACT_APP_API_URL}${item.image}`} alt={item.name} className="cart-item-image" />
                                    <div className="cart-item-details">
                                        <h3>{item.name}</h3>
                                        <p>{roundMoney(item.price)} usdt</p>
                                        <div className="quantity-control">
                                            <button onClick={() => updateQuantity(item._id, -1)} disabled={item.quantity <= 1}>-</button>
                                            <span>{item.quantity}</span>
                                            <button onClick={() => updateQuantity(item._id, 1)} disabled={item.quantity >= item.stock}>+</button>
                                        </div>
                                    </div>
                                    <div className="cart-item-stock">
                                        <p>库存: {item.stock}</p>
                                    </div>
                                    <button
                                        className="remove-item-btn"
                                        onClick={() => removeFromCart(item._id)}
                                    >
                                        &#x2715;
                                    </button>
                                </div>
                            ))}
                            <div className="contact-info">
                            <label htmlFor="contact">请添加收货方式（邮箱）：</label>
                            <input
                                    type="email"
                                    id="contact"
                                    value={contact}
                                    onChange={handleContactChange}
                                    placeholder="例如：example@email.com"
                                />
                            </div>
                            <div className="delivery-options">
                                <h3>是否</h3>
                                <div className="option">
                                    <input
                                        type="checkbox"
                                        id="standard"
                                        name="delivery"
                                        checked={deliveryOptions.standard}
                                        onChange={() => handleDeliveryOptionChange('standard')}
                                    />
                                    <label htmlFor="standard">请问是否加入新品订阅，每2周发一次邮件，告知产品更新情况</label>
                                    </div>
                            </div>
                            <div className="additional-info">
                            <p>温馨提示：</p>
                                <ul>
                                    <li>邮箱地址是接收商品的唯一凭证</li>
                                    <li>支持多链多币种智能扣款服务，请放心支付</li>
                                    <li>支付成功后，会在0—7分钟内，发货到邮箱。需要人工辅助服务的账号，邮箱内会附带辅助登录客服联系方式，请及时添加</li>
                                </ul>
                            </div>
                            <div className="cart-total">
                                <h3>总计: {calculatedTotalAmount} usdt</h3>
                                <div className="checkout-button-container">
                                    <button
                                        className="checkout-btn"
                                        disabled={!isValidEmail}
                                        onMouseEnter={handleCheckoutHover}
                                        onMouseLeave={handleCheckoutLeave}
                                        onTouchStart={handleCheckoutHover}
                                        onTouchEnd={handleCheckoutLeave}
                                        onClick={handleCheckout}
                                    >
                                        结算
                                    </button>
                                    {showTooltip && !isValidEmail && (
                                        <div className="tooltip">请输入正确的邮箱地址</div>
                                    )}
                                </div>
                            </div>
                            {/* 添*/}
                            <div className="telegram-contact">
                                <img
                                    src="https://telegram.org/img/t_logo.svg"
                                    alt="Telegram"
                                    className="telegram-icon"
                                />
                                <a
                                    href="https://t.me/huwangfaka"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="telegram-link"
                                >
                                    遇到问题，联系人工客服
                                </a>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <PaymentModal
                isOpen={isPaymentModalOpen}
                onClose={() => {
                    setIsPaymentModalOpen(false);
                    setIsVisible(false);  // 确保在关闭支付模态框时也关闭购物车
                }}
                onSelectPayment={handleSelectPayment}
                amount={calculatedTotalAmount}
            />
        </>
    );
}

export default CollapsibleCart;
